<template>
  <v-container fluid grid-list-lg>
    <div v-if="!editMode">
      <v-text-field v-model="configFilter" label="Filter" outlined class="height: 100vh;"></v-text-field>
      <v-data-table :headers="headers" :items="configsFiltered" item-key="key" class="elevation-1" :items-per-page="100">
        <template v-slot:body="{ items, headers }">
          <tbody>
            <tr v-for="(item, idx) in items" :key="idx">
              <template v-for="(header, i) in headers">
                <td :key="i" v-if="i > 0 && i < headers.length - 1">
                  <v-edit-dialog :return-value.sync="item[header.value]" @save="saveConfig(item.key, item.val)" large>
                    <span v-if="isColor(item)" class="colorField" :style="`background-color:${item[header.value]}`"></span>
                    <v-icon v-else-if="isBoolean(item)">{{ item[header.value] == "true" ? "mdi-check-bold" : "mdi-close-thick" }}</v-icon>
                    {{ item[header.value] }}

                    <template v-slot:input>
                      <v-color-picker v-if="isColor(item)" mode="hex" v-model="item[header.value]" class="mt-4" show-swatches></v-color-picker>
                      <v-radio-group v-else-if="isBoolean(item)" v-model="item[header.value]" row>
                        <v-radio label="True" value="true"></v-radio>
                        <v-radio label="False" value="false"></v-radio>
                      </v-radio-group>
                      <v-text-field v-model="item[header.value]" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td :key="i" v-else-if="i == headers.length - 1" style="width: 100px">
                  <v-btn icon @click="deleteConfig(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
                <td :key="i" v-else>
                  <span>
                    {{ item[header.value] }}
                  </span>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <v-card class="mt-5">
        <v-card-title>Add config</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="key" label="Key"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="val" label="Value"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-select v-model="type" :items="types" label="Type"></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn @click="createConfig(key, val, type)" class="mt-3">Add</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-textarea filled :label="$t('configs.editMode.textAreaLabel')" auto-grow v-model="configText"></v-textarea>
      <v-btn @click="updateConfig" color="primary" class="float-right">Update</v-btn>
    </div>
    <v-switch v-model="editMode" :label="$t('configs.switches.showEditMode')"></v-switch>
    <v-btn v-if="false" @click="resetConfig()" color="primary">Reset config</v-btn>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { formatMixin, listMixin } from "@/helpers/mixins";

export default {
  mixins: [formatMixin, listMixin],

  components: {},

  data() {
    return {
      headers: [
        { text: "Key", align: "left", sortable: true, value: "key" },
        { text: "Value", value: "val" },
        { text: "Actions", value: "" },
      ],
      configs: [],
      configFilter: "",
      editMode: false,
      configText: "",
      key: "",
      val: "",
      types: ["text", "number", "color", "boolean"],
      type: "text",
    };
  },

  computed: {
    ...mapGetters(["config"]),
    configsFiltered: function () {
      return this.configs.filter((c) => c.key.toLowerCase().indexOf(this.configFilter.toLowerCase()) >= 0);
    },
  },

  created() {
    this.loadConfigs();
  },

  methods: {
    isColor: function (item) {
      return item["configtype"] == "color";
    },
    isBoolean: function (item) {
      return item["configtype"] == "boolean";
    },

    loadConfigs: function () {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/admin/configs`)
        .then((response) => {
          this.configs = response.data;
          let ct = [...this.configs];

          ct.map((v) => {
            delete v.createdAt;
            delete v.updatedAt;
          });
          this.configText = JSON.stringify(ct);
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },

    createConfig: function (key, val, type) {
      axios
        .post(`/api/admin/configs`, { key: key, val: val, configtype: type })
        .then((msg) => {
          console.log(msg);
          this.key = "";
          this.val = "";
          this.type = "text";
          this.$store.dispatch("showSnackMessage", { message: "Data saved." });
          this.loadConfigs();
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },

    deleteConfig: function (item) {
      if (confirm("Are you sure you want to delete this config?")) {
        axios
          .delete(`/api/admin/configs/${item.key}`)
          .then(() => {
            this.$store.dispatch("showSnackMessage", { message: "Config deleted." });
            this.loadConfigs();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    saveConfig: function (key, val) {
      axios
        .post(`/api/admin/configs/${key}`, { val: val })
        .then((msg) => {
          console.log(msg);
          this.$store.dispatch("showSnackMessage", { message: "Data saved." });
          this.loadConfigs();
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },

    resetConfig: function () {
      axios
        .get(`/api/admin/configs/reset`)
        .then(() => {
          this.$store.dispatch("showSnackMessage", { message: "Config reseted." });
          this.loadConfigs();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updateConfig: function () {
      axios
        .post(`/api/admin/configs/saveconfig`, { datastr: this.configText })
        .then(() => {
          this.editMode = false;
          this.$store.dispatch("showSnackMessage", { message: "Config updated." });
          this.loadConfigs();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.colorField {
  width: 10px;
  padding: 1rem;
  margin-right: 1rem;
}
</style>
