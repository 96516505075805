<template>
  <DefaultLayout>
    <template v-slot:header>
      <v-spacer />
      <v-btn color="accent" @click="reloadConfig()">Reload config</v-btn>
    </template>

    <v-layout>
      <v-flex>
        <ConfigManager ref="configManager" />
      </v-flex>
    </v-layout>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default.vue";
import ConfigManager from "@/components/config/configmanager.vue";
import { loadTheme } from "@/services/theme.js";

export default {
  name: "usermngt",
  components: {
    DefaultLayout,
    ConfigManager,
  },
  methods: {
    reloadConfig: function() {
      this.$store.dispatch("loadConfig").then((u) => {
        console.log("Config loaded", u);
        this.$store.dispatch("showSnackMessage", { message: "Config loaded", color: "orange" });

        loadTheme({ ...u });
      });
    },
  },
};
</script>
